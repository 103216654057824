import styled, { keyframes } from "styled-components";


const appear = keyframes`
    from{
        opacity: 0;
    }to{
        opacity: 1;
    }
`;

const loadApp = keyframes`
    0%{
        opacity: .20;
    }10%{
        opacity: 1;
    }20%{
        opacity: .20;
    }30%{
        opacity: 1;
    }40%{
        opacity: .20;
    }50%{
        opacity: 1;
    }60%{
        opacity: .20;
    }70%{
        opacity: 1;
    }80%{
        opacity: .20;
    }90%{
        opacity: 1;
    }100%{
        opacity: 1;
`;

interface ContentProps {
    backgroundColor1?: string;
    backgroundColor2?: string;
}

export const Container = styled.div`
    width: 100%;
    max-width: 36.5rem;
    height: 23rem;
    padding: 1rem;

    border-radius: 1rem;

    @media (max-width: 540px) {
        max-width: 100%;
    }

    display: flex;
    align-items: center;
    justify-content: center;

    transition: color .3s;

    svg{
        color: transparent;
        position: absolute;
        width: 10%;
        height: 10%;
    }

    >h1{
        position: absolute;
        margin-top: 11rem;
        
        color: transparent;
        font-weight: 400;
    }

    &:hover{
        svg{
            color: red;
        }
        >h1{
            color: #fff;
        }

        div{
            opacity: 0;
        }
        
        border: 2px solid red;
    }

    animation: ${appear} .5s;
`;

export const Content = styled.div`
    width: 100%;
    height: 100%;

    display: flex;

    border-radius: 1rem;

    border: 2px solid #6B767D;

    transition: opacity .3s;

    &:hover{
        opacity: 0;
    }
`;

export const App = styled.div<ContentProps>`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h1{
        font-size: 4rem;
        font-weight: 200;

        &::first-letter{
            font-weight: 600;
        }
    }
    h2{
        font-size: 2rem;
    }
    p{
        font-size: 1.5rem;
        font-weight: 500;
        color: #181920;
    }

    &:first-child{
        border-radius: 1rem 0 0 1rem;
    }

    &:nth-child(2){
        border-radius: 0 1rem 1rem 0;
    }

    animation: ${loadApp} 10s;

    background-image: linear-gradient(115deg, ${props => props.backgroundColor1 ? props.backgroundColor1 : "#333A40"}, ${props => props.backgroundColor1 ? props.backgroundColor2 : "#333A40"});
`;