import { createContext, useContext, useState } from "react";
import { uuid } from "uuidv4";

interface ServerProps {
    apps: {
        name: string,
        dateAdded: number
    }[],
    serverId: string
}

interface NumApps { [key: string]: number }

interface PodsContextData {
    servers: ServerProps[],
    setServers(server: ServerProps[]): void,
    addServer(): void,
    destroyServer(): void,
    destroySpecificServer(serverId: string): void,
    addApp(appName: string): void,
    removeApp(appName: string): void,
    numApps: NumApps
}

const PodsContext = createContext<PodsContextData>({} as PodsContextData)

const PodsProvider: React.FC = ({ children }) => {

    const [servers, setServers] = useState<ServerProps[]>([
        { apps: [], serverId: uuid() },
        { apps: [], serverId: uuid() },
        { apps: [], serverId: uuid() },
        { apps: [], serverId: uuid() }
    ])

    const [numApps, setNumApps] = useState<NumApps>({
        Hadoop: 0,
        Rails: 0,
        Chronos: 0,
        Storm: 0,
        Spark: 0
    })

    function addServer() {
        setServers([...servers, { apps: [], serverId: uuid() }])
    }

    function destroyServer() {
        var newArray: ServerProps[] = [];

        const deletedServer = servers[servers.length - 1];

        if (deletedServer) {
            if (deletedServer.apps.length === 1) {
                addApp(deletedServer.apps[0].name, deletedServer.apps[0].dateAdded);
            } else if (deletedServer.apps.length === 2) {
                addApp(deletedServer.apps[0].name, deletedServer.apps[0].dateAdded);
                addApp(deletedServer.apps[1].name, deletedServer.apps[1].dateAdded);
            }
        }

        servers.forEach((element, index) => {
            if (index < servers.length - 1) {
                newArray.push(element);
            }
        });

        setServers(newArray);
    }

    function destroySpecificServer(serverId: string) {

        const deletedServer = servers.find(server => server.serverId === serverId);

        if (deletedServer) {
            if (deletedServer.apps.length === 1) {
                addApp(deletedServer.apps[0].name, deletedServer.apps[0].dateAdded);
            } else if (deletedServer.apps.length === 2) {
                addApp(deletedServer.apps[0].name, deletedServer.apps[0].dateAdded);
                addApp(deletedServer.apps[1].name, deletedServer.apps[1].dateAdded);
            }
        }

        const newArray = servers.filter(server => server.serverId !== serverId);
        setServers(newArray);
    }

    function addApp(appName: string, dateAdded?: number) {

        if (servers.length > 0) {
            let aux = 0;
            let newArray: ServerProps[] = [];

            newArray = servers.map(server => {
                if (server.apps.length === 0 && aux === 0) {
                    server.apps.push({ name: appName, dateAdded: dateAdded ? dateAdded : Date.now() });
                    aux++;
                }
                return server
            })

            newArray = servers.map(server => {
                if (server.apps.length === 1 && aux === 0) {
                    server.apps.push({ name: appName, dateAdded: dateAdded ? dateAdded : Date.now() });
                    aux++;
                }
                return server;
            })

            if (aux !== 0) {
                let alter = numApps;
                alter[appName] += 1;
                setNumApps(alter);
            }


            setServers(newArray);
        }
    }

    function removeApp(appName: string) {
        let aux = 0;
        let newArray: ServerProps[] = [];

        newArray = servers.map(server => {
            if (aux === 0) {
                const appsArray = server.apps.filter(app => app.name === appName && aux === 0 ? app && aux++ : app);
                server.apps = appsArray;
            }
            return server
        })

        if (numApps[appName] >= 1) {
            let alter = numApps;
            alter[appName] -= 1;
            setNumApps(alter);
        }

        setServers(newArray);
    }

    return (
        <PodsContext.Provider value={{
            servers,
            setServers,
            addServer,
            destroyServer,
            destroySpecificServer,
            addApp,
            removeApp,
            numApps
        }}>
            {children}
        </PodsContext.Provider>
    )
}

function usePods(): PodsContextData {
    const context = useContext(PodsContext);
    return context;
}

export { PodsProvider, usePods }