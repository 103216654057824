import { usePods } from '../../hooks/pods';
import Server from '../Server';

import { Container, Content } from './styles'

export default function Dashboard() {
    const { servers } = usePods();

    return (<Container>
        <Content>
            {servers.map(server => {
                return <Server serverApps={server.apps} serverId={server.serverId} />
            })}
        </Content>
    </Container>)
}