import Sidebar from '../../components/Sidebar'
import Dashboard from '../../components/Dashboard'

import { PodsProvider } from '../../hooks/pods'
import { CountdownProvider } from '../../hooks/countdown'
import { Container } from './styles'

export default function Home() {

    return (
        <Container>
            <CountdownProvider>
                <PodsProvider>
                    <Sidebar />
                    <Dashboard />
                </PodsProvider>
            </CountdownProvider>
        </Container>
    )

}