import { useEffect, useState } from 'react';
import { Container, Content, App } from './styles'
import { useCountdown } from '../../hooks/countdown'
import { TiDelete } from 'react-icons/ti'
import { usePods } from '../../hooks/pods';
interface ServerProps {
    serverApps: {
        name: string,
        dateAdded: number
    }[];
    serverId: string;
}

const serversColorsEnum: { [key: string]: string[] } = {
    "Hadoop": ["#BA1AC9","#FD00B7"],
    "Rails": ["#003AF1","#582EED"],
    "Chronos": ["#00C1F3","#006399"],
    "Storm": ["#00E38D", "#00E5BD"],
    "Spark": ["#69FD47","#35D43A"],
}

const appsSglEnum: { [key: string]: string } = {
    "Hadoop": "Hd",
    "Rails": "Rl",
    "Chronos": "Ch",
    "Storm": "St",
    "Spark": "Sp",
}


export default function Server({ serverApps, serverId }: ServerProps) {

    const { minutes, startCountdown } = useCountdown();
    const { destroySpecificServer } = usePods()

    const [firstAppMinutes, setFirstAppMinutes] = useState<number>();
    const [secondAppMinutes, setSecondAppMinutes] = useState<number>();

    function diffMins(initialDate: number, finalDate: number) {
        return Math.round(((Math.floor(finalDate - initialDate) % 86400000) % 3600000) / 60000);
    }

    useEffect(() => {
        startCountdown()
    }, [startCountdown])

    useEffect(() => {

        if (serverApps[0]) {
            setFirstAppMinutes(diffMins(serverApps[0].dateAdded, Date.now()))
        }

        if (serverApps[1]) {
            setSecondAppMinutes(diffMins(serverApps[1].dateAdded, Date.now()))
        }

    }, [minutes, serverApps])

    if (serverApps.length < 2) {
        return (
            <Container onClick={() => { destroySpecificServer(serverId) }}>
                <TiDelete />
                <h1>Delete</h1>
                {serverApps[0] ?
                    <Content>
                        <App backgroundColor1={serversColorsEnum[serverApps[0].name][0]} backgroundColor2={serversColorsEnum[serverApps[0].name][1]}>
                            <h1>{appsSglEnum[serverApps[0].name]}</h1>
                            <h2>{serverApps[0].name}</h2>
                            <p>Added {firstAppMinutes || 0} min ago</p>
                        </App>
                    </Content>
                    : (
                        <Content>
                        </Content>
                    )
                }
            </Container>
        )
    } else {
        return (
            <Container onClick={() => { destroySpecificServer(serverId) }}>
                <TiDelete />
                <h1>Delete</h1>
                <Content>
                    <App backgroundColor1={serversColorsEnum[serverApps[0].name][0]} backgroundColor2={serversColorsEnum[serverApps[0].name][1]}>
                        <h1>{appsSglEnum[serverApps[0].name]}</h1>
                        <h2>{serverApps[0].name}</h2>
                        <p>Added {firstAppMinutes || 0} min ago</p>
                    </App>
                    <App backgroundColor1={serversColorsEnum[serverApps[1].name][0]} backgroundColor2={serversColorsEnum[serverApps[1].name][1]}>
                        <h1>{appsSglEnum[serverApps[1].name]}</h1>
                        <h2>{serverApps[1].name}</h2>
                        <p>Added {secondAppMinutes || 0} min ago</p>
                    </App>
                </Content>
            </Container>
        )
    }
}