import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: 100%;
    background-color: #212529;

    display: flex;
    align-items: flex-start;
    justify-content: center;
    overflow-y: scroll;
`;

export const Content = styled.div`
    width: 100%;
    background-color: #212529;
    padding: 1rem;

    display: flex;
    flex-wrap: wrap;
`;