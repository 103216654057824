import { usePods } from '../../hooks/pods';

import { Container, Buttons, DivButtons, Apps, App, Button } from './styles'
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from 'react-icons/ai'
import { BsPlus } from 'react-icons/bs'

export default function Sidebar() {

    const { addServer, destroyServer, addApp, removeApp, numApps } = usePods();

    return (
        <Container>
            <Buttons>
                <DivButtons>
                    <button onClick={addServer}>
                        <AiOutlinePlusCircle />
                    </button>
                    <p>Novo servidor</p>
                </DivButtons>
                <DivButtons>
                    <button onClick={destroyServer}>
                        <AiOutlineMinusCircle />
                    </button>
                    <p>Destruir</p>
                </DivButtons>
            </Buttons>
            <Apps>
                <p>Apps disponíves</p>
                <App borderColor='#CE12C3'>
                    <p>Hadoop</p>
                    <p>{numApps["Hadoop"]}</p>
                    <div>
                        <Button onClick={() => removeApp("Hadoop")}>
                            <AiOutlineMinusCircle />
                        </Button>
                        <Button borderColor='#CE12C3' onClick={() => addApp("Hadoop")}>
                            <BsPlus />
                        </Button>
                    </div>
                </App>
                <App borderColor='#0039F0'>
                    <p>Rails</p>
                    <p>{numApps["Rails"]}</p>
                    <div>
                        <Button onClick={() => removeApp("Rails")}>
                            <AiOutlineMinusCircle />
                        </Button>
                        <Button borderColor='#0039F0' onClick={() => addApp("Rails")}>
                            <BsPlus />
                        </Button>
                    </div>
                </App>
                <App borderColor='#00ACDF'>
                    <p>Chronos</p>
                    <p>{numApps["Chronos"]}</p>
                    <div>
                        <Button onClick={() => removeApp("Chronos")}>
                            <AiOutlineMinusCircle />
                        </Button>
                        <Button borderColor='#00ACDF' onClick={() => addApp("Chronos")}>
                            <BsPlus />
                        </Button>
                    </div>
                </App>
                <App borderColor='#00E38C'>
                    <p>Storm</p>
                    <p>{numApps["Storm"]}</p>
                    <div>
                        <Button onClick={() => removeApp("Storm")}>
                            <AiOutlineMinusCircle />
                        </Button>
                        <Button borderColor='#00E38C' onClick={() => addApp("Storm")}>
                            <BsPlus />
                        </Button>
                    </div>
                </App>
                <App borderColor='#6AFD47'>
                    <p>Spark</p>
                    <p>{numApps["Spark"]}</p>
                    <div>
                        <Button onClick={() => removeApp("Spark")}>
                            <AiOutlineMinusCircle />
                        </Button>
                        <Button borderColor='#6AFD47' onClick={() => addApp("Spark")}>
                            <BsPlus />
                        </Button>
                    </div>
                </App>
            </Apps>
        </Container>
    )
}