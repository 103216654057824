import styled from "styled-components";

export const Container = styled.div`

    width: 100vw;
    height: 100vh;

    display: grid;
    grid-template-columns: 1fr 4fr;

    @media (max-width: 540px) {
        display: flex;
        flex-direction: column;
    }
    `;