import styled from "styled-components";

interface AppProps {
    borderColor?: string;
}

export const Container = styled.div`

    width: 100%;
    height: 100%;
    background-color: #2C3034;

    display: grid;
    grid-template-rows: 1fr 2fr 1fr;

    @media (max-width: 540px) {
       display: flex;
       flex-direction: column;
    }

`;

export const DivButtons = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    color: #6B767D;
    transition: .3s;

    &:hover {
        color: #fff;

        svg{
            color: #fff;
        }
    }

    button{
        width: 12rem;
        height: 12rem;
        
        border-radius: 50%;
        border: none;

        margin-bottom: 1rem;
        background-color: transparent;
        
        color: #6B767D;

        svg{
            width: 100%;
            height: 100%;
            transition: color .3s;
        }
    }

    p{
        font-size: 2rem;
        font-family: 'Roboto', sans-serif;
        text-align: center;
    }
`;

export const Buttons = styled.div`
    width: 100%;
    height: 20rem;

    display: flex;
    align-items: center;
    justify-content: space-evenly;

`;

export const Apps = styled.div`

    display: flex;
    justify-content: center;
    flex-direction: column;

    width: 100%;
    height: 100%;

    @media (max-width: 480px) {
        height: 50%;
    }

    >p{
        font-size: 2rem;
        margin: 0 0 2rem 4rem;
        color: #6B767D;
    }

`;

export const App = styled.div<AppProps>`
    width: 100%;
    height: 6rem;

    border-left: 3px solid ${props => props.borderColor ? props.borderColor : "#fff"};
    border-bottom: 1px solid #2C3034;

    background-color: #212529;

     &:first-child{
        border-top: 1px solid #2C3034;
    }

    display: grid;
    grid-template-columns: 3fr 1fr 2fr;

    p{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 2.5rem;
    }

    div{
        display: flex;
        align-items: center;
        justify-content: space-evenly;
    }
    
`;

export const Button = styled.button<AppProps>`
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    border: none;
    background-color: transparent;

    display: flex;
    align-items: center;
    justify-content: center;

    svg{
        width: 100%;
        height: 100%;
        color: #6B767D;
    }
    
    &:nth-child(2){
        svg{
            color: #fff;
        }
        background-color: ${props => props.borderColor ? props.borderColor : '#fff'};
    }
`;